import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { NoteBook } from '../../components/notebook';
import './index.css';

const emailToName = {
    "khannaseerahmed288@gmail.com": "Naseer",
    "nikitakmandyam@gmail.com": "Kanumoory"
}

class NotesPageState {
    notes = [];
    loading = true;
}

export function NotesPage() {
    const [notesPageState, setNotesPageState] = useState(new NotesPageState());
    const [user, _] = useLocalStorage("user", null);
    const name = emailToName[user.email];
    console.log('notes', notesPageState.notes);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                const response = await axios.get(`https://telegram-b65fq6mfuq-as.a.run.app/notes?from=${name}`, {});
                const notes = response.data.notes;
                // shuffle(notes);
                setNotesPageState({ notes: notes, loading: false });
            }
            catch (error) {
                console.error('Failed to fetch notes:', error);
                setNotesPageState({ ...notesPageState, loading: false });
                return
            }

        }
        fetchNotes();
    }, []);

    return (
        <div
            className='outer-container'
        >
            {
                notesPageState.loading ?
                    <CircularProgress /> :
                    <NoteBook notes={notesPageState.notes}></NoteBook>
            }

        </div>
    )
}